import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import img from '../img.png';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Grid from '@mui/material/Grid';
import { useContext, useState } from 'react';
import { UserContext } from '../UserContext';

import { addCartItem } from '../utils';


export default function CategoryHeader(props) {
    const { children, title, subtitle } = props;

    const DEFAULT_FILTERS = {
        colors: {
            isEnabled: false,
            isOpened: false,
            data: []
        },
        sizes: []
    }
    const [filters, setFilters] = useState(DEFAULT_FILTERS);

  return (
      <Box
          sx={(theme) => ({
              width: '100%',
              backgroundSize: '100% 20%',
              backgroundRepeat: 'no-repeat',
          })}
      >
          {/*<Container*/}
          {/*    container*/}
          {/*>*/}
          {/*</Container>*/}
          <Box
              sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  flexDirection: 'column',
                  pt: { xs: 14, sm: 20 },
                  pb: { xs: 8, sm: 12 },
              }}
          >
              <Box
                  sx={{
                      width: '100%'
                  }}
              >
                  <Container>
                      <Container>
                          <Typography variant='body1' align='left' width='100%'>
                              {title}
                          </Typography>
                      </Container>
                      <Container>
                          <Typography variant='h6' align='left' width='100%'>
                              {subtitle}
                          </Typography>
                      </Container>
                  </Container>
              </Box>
              <Box
                  sx={{
                      backgroundColor: "#fffcf7",
                      height: '50px',
                      width: '100%'
                  }}
              >
                  <Container
                      sx={{
                          display: 'flex',
                          // flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          height: '100%'
                          // pt: { xs: 14, sm: 20 },
                          // pb: { xs: 8, sm: 12 },
                      }}
                  >
                      <Container width={'100%'}
                                 sx={{
                                     display: 'flex',
                                     alignItems: 'center',
                                     justifyContent: 'flex-start',
                                     userSelect: 'none',
                                     '&:hover': {
                                         cursor: 'pointer'
                                     },
                                 }}
                      >
                          <Typography align={'left'}
                            onClick={() => {
                                setFilters((prevState) => ({...prevState, colors: { ...prevState.colors, isOpened: !prevState.colors.isOpened}}))
                            }}
                          >{`Цвет ${filters.colors.data.length ? "(" + filters.colors.data.length + ")" : ""}`}</Typography>
                          { !filters.colors.isOpened ?
                              <ExpandMoreIcon fontSize='small'
                                  onClick={ () => {
                                      setFilters((prevState) => ({...prevState, colors: { ...prevState.colors, isOpened: !prevState.colors.isOpened}}))
                                  }}/> :
                              <ExpandLessIcon fontSize='small'
                                  onClick={ () => {
                                      setFilters((prevState) => ({...prevState, colors: { ...prevState.colors, isOpened: !prevState.colors.isOpened}}))
                                  }}/>
                            }
                      </Container>
                      <Container> <Typography >Цвет (2)</Typography> </Container>
                      <Container> <Typography >Цвет (2)</Typography> </Container>
                      <Container> <Typography >Цвет (2)</Typography> </Container>
                  </Container>
              </Box>
              {
                  filters.colors.isOpened ?
                      <Box
                          sx={{
                              backgroundColor: "#fffcf7",
                              height: '100px',
                              width: '100%',
                              zIndex: '999'
                          }}
                      >

                      </Box>
                      : null
              }
              <Container
                  sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                      // pt: { xs: 14, sm: 20 },
                      // pb: { xs: 8, sm: 12 },
                  }}
              >
                  { children }
              </Container>
          </Box>
      </Box>
  );
}
