import * as React from 'react';
import PropTypes from 'prop-types';

import '../App.css'
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import menu from '../images/menu.jpg';
import Button from '@mui/material/Button';
import { useContext } from 'react';
import { UserContext } from '../UserContext';
import { useNavigate, useLocation } from 'react-router-dom';
import oppositeLogo from '../images/logos/Opposite-logo-cropped.png';
import oppositeStyleLogo from '../images/logos/Opposite-style-logo.png';
import Divider from "@mui/material/Divider";
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import AddIcon from '@mui/icons-material/Add';
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import {StyledBadge} from "./StyledBadge";
import IconButton from "@mui/material/IconButton";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: 'background.paper',
    maxWidth: '600px',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
};

function AppAppBar({ mode, toggleColorMode }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const [menu, setMenu] = useState({
      woman: {
          isOpened: false
      },
      man: {
          isOpened: false
      }
  })

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
    }
  };

  const [isMenuOpen, setMenuOpen] = useState(false);
  const handleMenuOpen = () => { setMenuOpen(true) };
  const handleMenuClose = () => { setMenuOpen(false) };

    const [isAddressOpen, setAddressOpen] = useState(false);
    const handleAddressOpen = () => {
        navigate('/about');
    };

    const handleStyleClick = () => {
        navigate('/style');
    };

    const handleStoreClick = () => {
        navigate('/store');
    };

    const {cart, updateState} = useContext(UserContext);

  return (
    <div className='AppAppBar' sx={{ backgroundColor: 'black' }}>
        <Modal
            open={isMenuOpen}
            onClose={ handleMenuClose }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} >
                <Button
                    variant="outlined"
                    href={'https://t.me/delta_burger'}
                >
                    Заказать
                </Button>
                <img
                    src={menu}
                />
                {/*<Document file={`${menu}`}>*/}
                {/*    <Page*/}
                {/*        pageNumber={1}*/}
                {/*        renderTextLayer={false}*/}
                {/*        renderAnnotationLayer={false}*/}
                {/*    />*/}
                {/*</Document>*/}
            </Box>
        </Modal>
        {/*<Modal*/}
        {/*    open={isAddressOpen}*/}
        {/*    aria-labelledby="modal-modal-title"*/}
        {/*    aria-describedby="modal-modal-description"*/}
        {/*>*/}
        {/*    <Box sx={style} >*/}
        {/*        <Typography variant='body1'>*/}
        {/*            Привет! Мы делаем эстетичный и вкусный фастфуд. Основа нашего меню - бургеры. Мы не тратимся на яркую рекламу, ресторанный зал и даже доставку,*/}
        {/*            чтобы держать приятные цены. Мы тратимся только на лучшие продукты. Например, в нашем бургере мраморное мясо, чистый состав и очень редкий рецепт. И его очень удобно есть.*/}
        {/*            Заказ готовим не дольше 10 минут. Наш самовывоз в одной минуте от метро Киевская. Адрес - Киевская ул., дом 2*/}
        {/*        </Typography>*/}
        {/*        <br/>*/}
        {/*        <div style={{position:"relative", overflow: "hidden"}}><a*/}
        {/*            href="src/components/AppAppBar?utm_medium=mapframe&utm_source=maps"*/}
        {/*            style={{color:"#eee",fontSize:"12px",position:"absolute",top:"0px"}}>Москва</a><a*/}
        {/*            href="src/components/AppAppBar?ll=37.562490%2C55.743601&utm_medium=mapframe&utm_source=maps&z=16.32"*/}
        {/*            style={{color:"#eee",fontSize:"12px",position:"absolute",top:"14px"}}>Киевская улица, 2 — Яндекс Карты</a>*/}
        {/*            <iframe*/}
        {/*                src="src/components/AppAppBar?ll=37.562490%2C55.743601&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgk3NzM2Mzg2MjUSOtCg0L7RgdGB0LjRjywg0JzQvtGB0LrQstCwLCDQmtC40LXQstGB0LrQsNGPINGD0LvQuNGG0LAsIDIiCg05QBZCFU_5XkI%2C&z=16.32"*/}
        {/*                width="560" height="400" frameBorder="1" allowFullScreen="true"*/}
        {/*                style={{position:"relative"}}></iframe>*/}
        {/*        </div>*/}
        {/*        <Typography>Часы работы с 10:00 до 14:00 каждый день</Typography>*/}
        {/*    </Box>*/}
        {/*</Modal>*/}
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          // bgcolor: 'transparent',
          backgroundColor: "#f6f1eb",
          backgroundImage: 'none'
          // mt: 2,
        }}
      >
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
                width: '100%',
              flexShrink: 0,
              // borderRadius: '999px',
              // bgcolor: '#fff',
                // theme.palette.mode === 'light'
                //   ? 'rgba(255, 255, 255, 0.4)'
                //   : 'rgba(0, 0, 0, 0.4)',
              // backdropFilter: 'blur(24px)',
              maxHeight: 40,
              // border: '1px solid',
                backgroundColor: 'transparent',
                borderColor: 'divider',
              // boxShadow:
              //   theme.palette.mode === 'light'
              //     ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
              //     : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
            })}
          >
            {/*<Box*/}
            {/*  sx={{*/}
            {/*    // flexGrow: 1,*/}
            {/*    display: 'flex',*/}
            {/*    alignItems: 'center',*/}
            {/*    justifyContent: 'space-between',*/}
            {/*    ml: '10px',*/}
            {/*    px: 0,*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <Box sx={{ display: { xs: 'none', md: 'flex' } }}>*/}
            {/*      /!*<MenuItem*!/*/}
            {/*      /!*    // onClick={() => scrollToSection('testimonials')}*!/*/}
            {/*      /!*    onClick={handleAddressOpen}*!/*/}
            {/*      /!*    sx={{ py: '6px', px: '12px' }}*!/*/}
            {/*      /!*    selected={false}*!/*/}
            {/*      /!*>*!/*/}
            {/*      /!*    <Typography variant="body2" color="text.primary">*!/*/}
            {/*      /!*        Доставка и оплата*!/*/}
            {/*      /!*    </Typography>*!/*/}

            {/*      /!*</MenuItem>*!/*/}
            {/*      /!*<MenuItem*!/*/}
            {/*      /!*    // onClick={() => scrollToSection('testimonials')}*!/*/}
            {/*      /!*    onClick={handleAddressOpen}*!/*/}
            {/*      /!*    sx={{ py: '6px', px: '12px' }}*!/*/}
            {/*      /!*    selected={false}*!/*/}
            {/*      /!*>*!/*/}
            {/*      /!*    <Typography variant="body2" color="text.primary">*!/*/}
            {/*      /!*        Контакты*!/*/}
            {/*      /!*    </Typography>*!/*/}

            {/*      /!*</MenuItem>*!/*/}
            {/*      <MenuItem*/}
            {/*          // onClick={() => scrollToSection('testimonials')}*/}
            {/*          onClick={handleAddressOpen}*/}
            {/*          sx={{ ml: 2, py: '24px', px: '12px' }}*/}
            {/*          selected={false}*/}
            {/*      >*/}
            {/*          <Typography variant="h6" color="text.primary">*/}
            {/*              О Нас*/}
            {/*          </Typography>*/}
            {/*      </MenuItem>*/}
            {/*      <MenuItem*/}
            {/*          // onClick={() => scrollToSection('testimonials')}*/}
            {/*          onClick={handleDeliveryClick}*/}
            {/*          sx={{py: '24px', px: '12px' }}*/}
            {/*          selected={false}*/}
            {/*      >*/}
            {/*          <Typography variant="h6" color="text.primary">*/}
            {/*              Доставка и оплата*/}
            {/*          </Typography>*/}
            {/*      </MenuItem>*/}
            {/*      <img*/}
            {/*          src={*/}
            {/*              // 'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e6faf73568658154dae_SitemarkDefault.svg'*/}
            {/*              //   'https://images.getbento.com/accounts/4e374d4ae4accf2cfd4def8d1d426233/media/images/76722hamburger_america_logo_05a.png'*/}
            {/*              `${oppLogo}`*/}
            {/*          }*/}
            {/*          style={logoStyle}*/}
            {/*          onClick={() => { navigate('/')}}*/}
            {/*          alt="Бургер на киевкой"*/}
            {/*      />*/}
            {/*      <MenuItem*/}
            {/*          // onClick={() => scrollToSection('testimonials')}*/}
            {/*          onClick={handleStoreClick}*/}
            {/*          sx={{py: '24px', px: '12px' }}*/}
            {/*          selected={false}*/}
            {/*      >*/}
            {/*          <Typography variant="h6" color="text.primary">*/}
            {/*              Yap магазин*/}
            {/*          </Typography>*/}
            {/*      </MenuItem>*/}
            {/*    /!*<MenuItem*!/*/}
            {/*    /!*  onClick={() => scrollToSection('highlights')}*!/*/}
            {/*    /!*  sx={{ py: '6px', px: '12px' }}*!/*/}
            {/*    /!*>*!/*/}
            {/*    /!*  <Typography variant="body2" color="text.primary">*!/*/}
            {/*    /!*    Highlights*!/*/}
            {/*    /!*  </Typography>*!/*/}
            {/*    /!*</MenuItem>*!/*/}
            {/*    /!*<MenuItem*!/*/}
            {/*    /!*  onClick={() => scrollToSection('pricing')}*!/*/}
            {/*    /!*  sx={{ py: '6px', px: '12px' }}*!/*/}
            {/*    /!*>*!/*/}
            {/*    /!*  <Typography variant="body2" color="text.primary">*!/*/}
            {/*    /!*    Pricing*!/*/}
            {/*    /!*  </Typography>*!/*/}
            {/*    /!*</MenuItem>*!/*/}
            {/*    /!*<MenuItem*!/*/}
            {/*    /!*  onClick={() => scrollToSection('faq')}*!/*/}
            {/*    /!*  sx={{ py: '6px', px: '12px' }}*!/*/}
            {/*    /!*>*!/*/}
            {/*    /!*  <Typography variant="body2" color="text.primary">*!/*/}
            {/*    /!*    FAQ*!/*/}
            {/*    /!*  </Typography>*!/*/}
            {/*    /!*</MenuItem>*!/*/}


            {/*     <MenuItem>*/}

            {/*         <Button*/}
            {/*             onClick={() => {*/}
            {/*                 if (cart.items.map(it => it.count).reduce((curr,acc) => curr + acc, 0) != 0) {*/}
            {/*                     updateState({cart: {...cart, open: true}})}*/}
            {/*                 }*/}
            {/*             }*/}
            {/*         >*/}
            {/*             <Badge badgeContent={cart.items.map(it => it.count).reduce((acc, curr) => acc + curr, 0)} color="error">*/}
            {/*                 <AddShoppingCart*/}
            {/*                     sx={{color: 'black'}}*/}
            {/*                 />*/}
            {/*             </Badge>*/}
            {/*         </Button>*/}
            {/*     </MenuItem>*/}

            {/*  </Box>*/}
            {/*    <Box sx={{display: { md: 'flex', xs: 'none'}, alignItems: 'center'}}>*/}
            {/*        <Typography variant='h6' sx={{color: 'black', pr: 2}}>*/}
            {/*            +7 (995) 787-26-42*/}
            {/*        </Typography>*/}
            {/*        <Link href="https://t.me/delta_burger">*/}
            {/*            <TelegramIcon sx={{color: '#0088cc'}}/>*/}
            {/*        </Link>*/}
            {/*    </Box>*/}
            {/*</Box>*/}
            {/*<Box*/}
            {/*  sx={{*/}
            {/*    display: { xs: 'none', md: 'flex' },*/}
            {/*    gap: 0.5,*/}
            {/*    alignItems: 'center',*/}
            {/*  }}*/}
            {/*>*/}
            {/*  /!*<ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />*!/*/}
            {/*  /!*<Button*!/*/}
            {/*  /!*  color="primary"*!/*/}
            {/*  /!*  variant="text"*!/*/}
            {/*  /!*  size="small"*!/*/}
            {/*  /!*  component="a"*!/*/}
            {/*  /!*  href="/material-ui/getting-started/templates/sign-in/"*!/*/}
            {/*  /!*  target="_blank"*!/*/}
            {/*  /!*>*!/*/}
            {/*  /!*  Sign in*!/*/}
            {/*  /!*</Button>*!/*/}
            {/*  /!*<Button*!/*/}
            {/*  /!*  color="primary"*!/*/}
            {/*  /!*  variant="contained"*!/*/}
            {/*  /!*  size="small"*!/*/}
            {/*  /!*  component="a"*!/*/}
            {/*  /!*  href="/material-ui/getting-started/templates/sign-up/"*!/*/}
            {/*  /!*  target="_blank"*!/*/}
            {/*  /!*>*!/*/}
            {/*  /!*  Sign up*!/*/}
            {/*  /!*</Button>*!/*/}
            {/*</Box>*/}
            <Box
                sx={{
                    width: '100%',
                    // display: { sm: ''}
                    display: 'flex',
                    alignItems: 'center',
                    // position: 'relative',
                    // justifyContent: 'flex-start',
                    // '&::after': {
                    //     content: '""',
                    //     flex: 1
                    // }
                }}>
                {/*<Button*/}
                {/*    onClick={() => {*/}
                {/*        if (cart.items.map(it => it.count).reduce((curr,acc) => curr + acc, 0) != 0) {*/}
                {/*            updateState({cart: {...cart, open: true}})}*/}
                {/*    }*/}
                {/*    }*/}
                {/*>*/}
                {/*    <Badge badgeContent={cart.items.map(it => it.count).reduce((acc, curr) => acc + curr, 0)} color="error">*/}
                {/*        <AddShoppingCart*/}
                {/*            sx={{color: 'black'}}*/}
                {/*        />*/}
                {/*    </Badge>*/}
                {/*</Button>*/}
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}
                >
                    <Button
                        variant="text"
                        aria-label="menu"
                        onClick={toggleDrawer(true)}
                        sx={{
                            minWidth: '30px', p: '4px', color: 'black',
                        }}
                    >
                        <MenuIcon
                            sx={{pr: 1}}
                        />
                        <Typography display={{xs: 'none', md: 'block' }}>Menu</Typography>
                    </Button>
                </Box>
                <Box
                    component='img'
                    onClick={() => { navigate('/')}}
                    alt="Логотип бренда opposite"
                    sx={{
                        // flex: 1
                        height: pathname == '/style' ? {xs: '45px', md: '65px'} : {xs: '40px', md: '58px'},
                        width: pathname == '/style' ? {xs: '175px', md: '260px'} : {xs: '100px', md: '148px'},
                        cursor: 'pointer',
                    }}
                    src={ pathname == '/style' ? `${oppositeStyleLogo}` : `${oppositeLogo}`}
                >
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingRight: '50px'
                        // width: '100%'
                    }}
                >
                    {
                        pathname != '/cart' ?
                            <IconButton
                                aria-label="cart"
                                onClick={() => navigate("/cart")}
                            >
                                <StyledBadge
                                    sx={{
                                        fontWeight: '900'
                                    }}
                                    badgeContent={ cart && cart.products && cart.products.length} color="secondary">
                                    <ShoppingBagIcon
                                        sx={{
                                            color: 'gray'
                                        }}
                                    />
                                </StyledBadge>
                            </IconButton>
                            : null
                    }
                </Box>
              <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}
                      PaperProps={{
                          sx: {
                              width: {
                                  xs: "100%",
                                  sm: '400px'
                              }
                          }
                      }}
              >
                <Box
                  sx={{
                    p: 2,
                  }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'middle'
                        }}
                    >
                        <Typography
                            sx={{
                                pb: 2,
                                userSelect: 'none',
                            }}
                        >
                            MENU
                        </Typography>
                        <ClearIcon/>
                    </Box>
                    <Typography
                        sx={{
                            '&:hover': {
                                cursor: 'pointer'
                            },
                            userSelect: 'none',
                            pb: 1
                        }}
                        onClick={() => {
                            setMenu((ps) => ({
                                ...ps, woman: { ...ps.woman, isOpened: !ps.woman.isOpened}
                                }))
                        }}
                    >
                        Женщины
                    </Typography>
                    {
                        menu.woman.isOpened ?
                            <Box
                                sx={{
                                    pl: 2,
                                    pb: 1
                                }}
                            >
                                <Typography
                                    sx={{
                                        '&:hover': {
                                            cursor: 'pointer'
                                        },
                                        userSelect: 'none',
                                        pb: 1
                                    }}
                                    onClick={() => {
                                        setOpen(false);
                                        navigate("/category/women/accessories");
                                    }}
                                >
                                    Аксессуары
                                </Typography>
                                <Typography
                                    sx={{
                                        '&:hover': {
                                            cursor: 'pointer'
                                        },
                                        userSelect: 'none',
                                        pb: 1
                                    }}
                                    onClick={() => {
                                        setOpen(false);
                                        navigate("/category/women/dresses")
                                    }}
                                >
                                    Платья и юбки
                                </Typography>
                            </Box>
                            : null
                    }
                    <Typography>
                        Мужчины
                    </Typography>
                    {/*<MenuItem*/}
                    {/*    // onClick={() => scrollToSection('testimonials')}*/}
                    {/*    onClick={handleAddressOpen}*/}
                    {/*    sx={{ py: '12px', px: '12px' }}*/}
                    {/*>*/}
                    {/*    <Typography variant="subtitle1" color="text.primary">*/}
                    {/*        Menu*/}
                    {/*    </Typography>*/}
                    {/*</MenuItem>*/}
                    {/*<MenuItem*/}
                    {/*    // onClick={() => scrollToSection('testimonials')}*/}
                    {/*    onClick={() => { navigate('/category/women')}}*/}
                    {/*    sx={{ py: '0', px: '0' }}*/}
                    {/*>*/}
                    {/*    <Container*/}
                    {/*        sx={{*/}
                    {/*            display: 'flex',*/}
                    {/*            flexDirection: 'column',*/}
                    {/*            alignItems: 'center',*/}
                    {/*            justifyContent: 'flex-start',*/}
                    {/*            width: '100%'*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <Typography variant="subtitle1" color="text.primary">*/}
                    {/*            Женщины*/}
                    {/*        </Typography>*/}
                    {/*        <Box*/}
                    {/*            sx={{*/}
                    {/*                maxWidth: {*/}
                    {/*                    xs: '100%', sm: '100dvw'*/}
                    {/*                },*/}
                    {/*                p: 2,*/}
                    {/*                backgroundColor: 'background.paper',*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            <MenuItem>*/}
                    {/*                <Typography variant="subtitle1" color="text.primary">*/}
                    {/*                    Платья и юбки*/}
                    {/*                </Typography>*/}
                    {/*            </MenuItem>*/}
                    {/*            /!*<AddIcon sx={{ fontSize: 'medium'}}/>*!/*/}
                    {/*        </Box>*/}
                    {/*    </Container>*/}
                    {/*</MenuItem>*/}
                    {/*<MenuItem*/}
                    {/*    // onClick={() => scrollToSection('testimonials')}*/}
                    {/*    onClick={handleStyleClick}*/}
                    {/*    sx={{ py: '0', px: '12px' }}*/}
                    {/*>*/}
                    {/*    <Typography variant="subtitle1" color="text.primary">*/}
                    {/*        Opposite Style*/}
                    {/*    </Typography>*/}
                    {/*</MenuItem>*/}
                    {/*<Divider/>*/}
                    {/*<MenuItem*/}
                    {/*    // onClick={() => scrollToSection('testimonials')}*/}
                    {/*    onClick={handleAddressOpen}*/}
                    {/*    sx={{ py: '6px', px: '12px' }}*/}
                    {/*>*/}
                    {/*    <Typography variant="subtitle1" color="text.primary">*/}
                    {/*        О нас*/}
                    {/*    </Typography>*/}

                    {/*</MenuItem>*/}
                    {/*<MenuItem*/}
                    {/*    // onClick={() => scrollToSection('testimonials')}*/}
                    {/*    onClick={handleDeliveryClick}*/}
                    {/*    sx={{ py: '6px', px: '12px' }}*/}
                    {/*>*/}
                    {/*    <Typography variant="h6" color="text.primary">*/}
                    {/*        Yap магазин*/}
                    {/*    </Typography>*/}
                    {/*</MenuItem>*/}
                  {/*<Box*/}
                  {/*  sx={{*/}
                  {/*    display: 'flex',*/}
                  {/*    flexDirection: 'column',*/}
                  {/*    alignItems: 'end',*/}
                  {/*    flexGrow: 1,*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />*/}
                  {/*</Box>*/}
                  {/*<MenuItem onClick={() => scrollToSection('features')}>*/}
                  {/*  Features*/}
                  {/*</MenuItem>*/}
                  {/*<MenuItem onClick={() => scrollToSection('testimonials')}>*/}
                  {/*  Testimonials*/}
                  {/*</MenuItem>*/}
                  {/*<MenuItem onClick={() => scrollToSection('highlights')}>*/}
                  {/*  Highlights*/}
                  {/*</MenuItem>*/}
                  {/*<MenuItem onClick={() => scrollToSection('pricing')}>*/}
                  {/*  Pricing*/}
                  {/*</MenuItem>*/}
                  {/*<MenuItem onClick={() => scrollToSection('faq')}>FAQ</MenuItem>*/}
                  {/*<Divider />*/}
                  {/*<MenuItem>*/}
                  {/*  <Button*/}
                  {/*    color="primary"*/}
                  {/*    variant="contained"*/}
                  {/*    component="a"*/}
                  {/*    href="/material-ui/getting-started/templates/sign-up/"*/}
                  {/*    target="_blank"*/}
                  {/*    sx={{ width: '100%' }}*/}
                  {/*  >*/}
                  {/*    Sign up*/}
                  {/*  </Button>*/}
                  {/*</MenuItem>*/}
                  {/*<MenuItem>*/}
                  {/*  <Button*/}
                  {/*    color="primary"*/}
                  {/*    variant="outlined"*/}
                  {/*    component="a"*/}
                  {/*    href="/material-ui/getting-started/templates/sign-in/"*/}
                  {/*    target="_blank"*/}
                  {/*    sx={{ width: '100%' }}*/}
                  {/*  >*/}
                  {/*    Sign in*/}
                  {/*  </Button>*/}
                  {/*</MenuItem>*/}
                </Box>
                  {/*<Box sx={{display: 'flex', alignItems: 'center', p: 2, backgroundColor: 'background.paper'}}>*/}
                  {/*    <Typography variant='h6' sx={{color: 'black', pr: 2}}>*/}
                  {/*        +7 (995) 787-26-42*/}
                  {/*    </Typography>*/}
                  {/*    <Link href="https://t.me/delta_burger">*/}
                  {/*        <TelegramIcon sx={{color: '#0088cc'}}/>*/}
                  {/*    </Link>*/}

                  {/*</Box>*/}
              </Drawer>
            </Box>
          </Toolbar>
      </AppBar>
    </div>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;
