import balaclava_1 from './images/balaclava/balaclava_1.jpg';
import balaclava_2 from './images/balaclava/balaclava_2.jpg';
import vm_pink_2_small from './images/pink-dress/vm_pink_2_small.jpg';
import vm_pink_4_small from './images/pink-dress/vm_pink_4_small.jpg';
import {CATEGORIES, GENDERS, SEASONS} from "./utils";

const productsProd = [
    {
        id: 1,
        imageList: [
            vm_pink_2_small, vm_pink_4_small
        ],
        productSrc: "",
        description: "Платье шорты из итальянской вискозы пудрового цвета",
        title: "Платье шорты Lily",
        price: 6990,
        currency: "руб",
        sizes: ["42", "44", "46"],
        color: "Пудровый",
        genders: [GENDERS.WOMEN],
        categories: [CATEGORIES.DRESSES_AND_SKIRTS],
        seasons: [SEASONS.SS],
        related: [],
        colors: []
    },
]

const productsInt = [
    {
        id: 1,
        imageList: [
            balaclava_1, balaclava_2
        ],
        productSrc: "hello",
        description: "Travel bag in H Plume canvas with \"Circuit 24\" motif, shoulder strap, collapsible handle, exterior pocket and Clou de Selle closure\n" +
            "\n" +
            "Сделано в Москве\n" +
            "\n" +
            "Metallic finish: Palladium plated\n" +
            "\n" +
            "Measures 19.7\" long, 13.4\" high and 8.9\" deep",
        title: "Шерстяная балаклава",
        price: "3990 руб",
        sizes: ["42", "44", "46"],
        color: "Черный",
        genders: [GENDERS.WOMEN],
        categories: [CATEGORIES.ACCESSORIES],
        seasons: [SEASONS.SS],
        related: [],
        colors: [1, 2]
    },
    {
        id: 2,
        imageList: [
            vm_pink_2_small, vm_pink_4_small
        ],
        productSrc: "hello",
        description: "description",
        title: "Платье шорты Лилия",
        color: "Белый",
        price: "6990 руб",
        sizes: ["42", "44", "46"],
        genders: [GENDERS.WOMEN],
        categories: [CATEGORIES.DRESSES_AND_SKIRTS],
        seasons: [SEASONS.SS],
        related: [],
        colors: [1,2]
    },
    {
        id: 3,
        imageList: [
            vm_pink_2_small, vm_pink_4_small
        ],
        productSrc: "hello",
        description: "description",
        color: "Розовый",
        title: "Платье шорты Лилия",
        sizes: ["42", "44", "46"],
        colors: [],
        genders: [GENDERS.WOMEN],
        categories: [CATEGORIES.DRESSES_AND_SKIRTS],
        seasons: [SEASONS.SS],
        related: [],
        price: "6990 руб"
    }
]

const products = productsProd;

export default products;

